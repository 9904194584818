import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Loginservice } from "./login.service";

import { ConfirmedValidator } from "../../shared/custom-validator/confirmed.validator";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { GolferService } from "../golfer/golfer.service";
import { ShotLockerService } from "../../shared/service/shot-locker.service";
import { ToastrService } from "ngx-toastr";

// dbjhbvhvgdvghvhgvghdvghdcgvcgh

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.css"],
})
export class LoginComponent implements OnInit {
  currentPage: string;

  isLoginForm = "login";
  // user: SocialUser;
  loggedIn: boolean;
  emailId;
  isSubscription;
  sub_type;
  userDetails;

  loginForm = new FormGroup({
    email: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });
  signUpForm = this.fb.group(
    {
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.required],
      password: ["", [Validators.required, Validators.minLength(8)]],
      confirmpassword: [""],
    },
    {
      validator: ConfirmedValidator("password", "confirmpassword"),
    }
  );

  forgotPasswordForm = new FormGroup({
    email: new FormControl("", [Validators.email, Validators.required]),
  });

  resetPasswordForm = this.fb.group(
    {
      otp: ["", Validators.required],
      password: ["", [Validators.required, Validators.minLength(8)]],
      confirmpassword: [""],
    },
    {
      validator: ConfirmedValidator("password", "confirmpassword"),
    }
  );

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private login: Loginservice,
    private locker: ShotLockerService,
    private fb: FormBuilder,
    private golfer: GolferService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.currentPage =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
      console.log(this.currentPage,"sbhv")
    this.isSubscription = localStorage.getItem("isSubcription");
    this.sub_type = localStorage.getItem("sub_type");
  }

  get f() {
    return this.signUpForm.controls;
  }

  get f1() {
    return this.resetPasswordForm.controls;
  }

  // Google Login

  // signInWithGoogle(): void {
  //     console.log("sjgjhsgjsghhjsghj")
  //     this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
  //         this.login.googleLogin(res['authToken']).subscribe(response => {
  //             console.log("api integration res", response)
  //         })

  //     })
  // }

  // signOut(): void {
  //     this.authService.signOut();
  // }

  toggle(signuporLogin) {
    this.isLoginForm = signuporLogin;
  }

  // On Login

  onSubmit() {
    this.spinner.show();
    localStorage.removeItem('access_token')
    this.loginForm.value;
    this.login
      .doLogin(this.loginForm.value, this.currentPage)
      .subscribe((data) => {
        console.log("subsucribe", data);
        console.log(data.status);
        this.isSubscription = data.body.subscription_status;
        console.log(this.isSubscription, this.sub_type ,"value kya hai")
        if (this.currentPage == "subscription") {
          if (this.isSubscription == null && this.sub_type == 'monthly' || this.sub_type == 'yearly') {
            this.spinner.show();
            console.log("fiesr");
            this.locker
            .sendRequest( "GET", "/api/subscription/check-status", null,null, null, null,false).subscribe((response) => {
              console.log(response, "subscription status");
              if (response.status == 200 && response.body.subscription_status != null) {
                this.router.navigate(["subscription"]);
              } else {
                this.login
                .subscription(this.sub_type)
                .subscribe((response) => {
                 if (response.status == 200) {
                  console.log(
                    response.body.message,
                    "payment response"
                  );
                  window.open(
                    response.body.message.checkout_url,
                    "_self"
                  );
                  this.spinner.hide();
                 } else {
                  this.toastr.error(response.error.message, "", {
                    closeButton: true,
                  });
                 }
                });
              }
            });
           

            // this.router.navigate(["subscription"]);
          } else {
            console.log("else")
            this.router.navigate(["subscription"]);
          }

        //   this.router.navigate(["subscription"]);
        } else {
          this.router.navigate(["locker-room"]);
          this.spinner.hide();
        }
      });
    this.loginForm.reset();
  }

  addpaymentCard() {
    this.locker
      .sendRequest(
        "GET",
        "/api/subscription/create-payment-method",
        null,
        null,
        null,
        null,
        false
      )
      .subscribe((response) => {
        console.log(response, "payment meti");
        localStorage.setItem(
          "client_secret",
          response.body.message.client_secret
        );
        localStorage.setItem(
          "publishable_key",
          response.body.message.publishable_key
        );
        this.router.navigate(["/stripe/add-card"]);
      });
  }
  // On sign up

  onSignup() {
    this.signUpForm.value;
    this.login.doSignUp(this.signUpForm.value).subscribe((data) => {
      console.log("sign up data", data);
      console.log("SignUp status", data.status);
      if (data.status == 201) {
        this.signUpForm.reset();
        this.isLoginForm = "login";
      }
    });
    // this.loginForm.reset()
  }

  hideError() {
    document.getElementById("emailerror").style.display = "none";
  }

  hide() {
    document.getElementById("errpass").style.display = "none";
  }

  // hidemsg(){
  //     document.getElementById('loginerr').style.display = "none";
  // }

  //  password reset

  onsendrequest() {
    this.emailId = this.forgotPasswordForm.value.email;
    console.log(this.forgotPasswordForm.value, "email");
    this.login
      .passwordResetRequest(this.forgotPasswordForm.value)
      .subscribe((res) => {
        console.log(res, "password rest");
        if (res.status == 200) {
          this.isLoginForm = "resetpassword";
        }
      });
  }

  onresetPassword() {
    console.log(this.resetPasswordForm.value, "otp subbmission");
    let bodyData = {
      new_password1: this.resetPasswordForm.value.password,
      new_password2: this.resetPasswordForm.value.confirmpassword,
      otp: this.resetPasswordForm.value.otp,
      email: this.emailId,
    };
    this.login.otpSubmission(bodyData).subscribe((res) => {
      console.log(res, "otp submitted");
      if (res.status == 200) {
        this.isLoginForm = "login";
      }
    });
  }
}
