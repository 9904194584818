import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";


@Injectable({
  providedIn: 'root'
})
export class ShotLockerService {

  constructor(private local: LocalStorageService, private http: HttpClient,private toastr: ToastrService,private router:Router,private spinner:NgxSpinnerService) { }

  sendRequest(requestType: string, context: string,
    reqBody: any, headers: HttpHeaders, queryString: any, action: string,
    flag: boolean) {

    //TO show Notification :: flag should be set to true
    //                        flag should be set to false
    //                        if errrors occurs Notifacation always be shown

    // console.log(`Sending Request to API  :Request Type = `, requestType,
    //     ` //API context = `, context, `//API action = ` , action, ` // reqbody = `,JSON.stringify(reqBody),
    //     ` // query string = "`, queryString, `"`);




    let responseType = "json";



    let url = `https://shot-locker.com${context}`;

    let authkey = this.local.getAccessToken();
    if (headers == null || headers == undefined) {
      headers = new HttpHeaders();
    }
    if (authkey != null && authkey != undefined && authkey != "undefined") {
      headers = headers.append('Authorization', `Bearer ${authkey}`);

    }

    let option: any = {
      headers: headers,
      responseType: responseType,
      observe: "response"

    }

    if (queryString != null) {
      option['params'] = queryString
    }


    switch (requestType.toUpperCase()) {

      case 'POST':
        return new Observable<any>(observe => {
          this.http.post(url, reqBody, option).subscribe(
            (response) => {
              observe.next(response)
            }, (error) => {
              if (error.status === 401) {
                // Handle 401 Unauthorized error
                this.handleUnauthorizedError();
              }
              observe.next(error)
            }
          )
        });
      case 'GET':
        return new Observable<any>(observe => {
          this.http.get(url, option).subscribe((response) => {
            observe.next(response)
          }, (error) => {
            if (error.status === 401) {
              // Handle 401 Unauthorized error
              this.handleUnauthorizedError();
            }
            observe.next(error)
          }
          )
        })
      case 'PUT':
        return new Observable<any>(observe => {
          this.http.put(url, reqBody, option).subscribe((response) => {
            observe.next(response)
          }, (error) => {
            if (error.status === 401) {
              // Handle 401 Unauthorized error
              this.handleUnauthorizedError();
            }
            observe.next(error)
          })
        });

      case 'DELETE':
        return new Observable<any>(observe => {
          this.http.delete(url, option).subscribe((response) => {
            observe.next(response)
          }, (error) => {
            if (error.status === 401) {
              // Handle 401 Unauthorized error
              this.handleUnauthorizedError();
            }
            observe.next(error)
          })
        });
    }

  }

  handleUnauthorizedError() {
    this.toastr.error("Session expired please login again", "", {
      closeButton: true,
      positionClass: "toast-bottom-center",
    });
    this.spinner.hide()
    this.router.navigate(["/login/subscription"]);
  }
}
